define('ember-highcharts/components/high-charts', ['exports', 'ember', 'ember-highcharts/utils/option-loader', 'ember-highcharts/utils/chart-data', 'ember-highcharts/templates/components/high-charts'], function (exports, _ember, _emberHighchartsUtilsOptionLoader, _emberHighchartsUtilsChartData, _emberHighchartsTemplatesComponentsHighCharts) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;

  var assign = _ember['default'].assign || _ember['default'].merge;

  exports['default'] = Component.extend({
    layout: _emberHighchartsTemplatesComponentsHighCharts['default'],
    classNames: ['highcharts-wrapper'],
    content: undefined,
    mode: undefined,
    chartOptions: undefined,
    chart: null,
    theme: undefined,
    callback: undefined,

    buildOptions: computed('chartOptions', 'content.[]', function () {
      var chartOptions = $.extend(true, {}, get(this, 'theme'), get(this, 'chartOptions'));
      var chartContent = get(this, 'content');

      // if 'no-data-to-display' module has been imported, keep empty series and leave it to highcharts to show no data label.
      if (!get(this, 'content.length') && !Highcharts.Chart.prototype.showNoData) {
        chartContent = [{
          id: 'noData',
          data: 0,
          color: '#aaaaaa'
        }];
      }

      var defaults = { series: chartContent };

      return assign(defaults, chartOptions);
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'content', 'chart', 'mode');

      var content = _getProperties.content;
      var chart = _getProperties.chart;
      var mode = _getProperties.mode;

      if (!content || !chart) {
        return;
      }

      var isStockChart = mode === 'StockChart';

      // create maps to make series data easier to work with
      var contentSeriesMap = (0, _emberHighchartsUtilsChartData.getSeriesMap)(content);
      var chartSeriesMap = (0, _emberHighchartsUtilsChartData.getSeriesMap)(chart.series);

      // remove and update current series
      var chartSeriesToRemove = [];

      chart.series.forEach(function (series) {
        if (isStockChart && series.name === 'Navigator') {
          return;
        }

        var contentSeries = contentSeriesMap[series.name];

        if (!contentSeries) {
          return chartSeriesToRemove.push(series);
        }

        var updatedKeys = (0, _emberHighchartsUtilsChartData.getSeriesChanges)(contentSeries, series);

        // call series.update() when other series attributes like pointStart have changed
        if (updatedKeys.length) {
          series.update(contentSeries, false);
        } else {
          series.setData(contentSeries.data, false);
        }
      });

      chartSeriesToRemove.forEach(function (series) {
        return series.remove(false);
      });

      // add new series
      content.forEach(function (contentSeries) {
        if (!chartSeriesMap.hasOwnProperty(contentSeries.name)) {
          chart.addSeries(contentSeries, false);
        }
      });

      // reset navigator data
      if (isStockChart && chart.xAxis.length) {
        chart.xAxis[0].setExtremes();
      }

      return chart.redraw();
    },

    drawAfterRender: function drawAfterRender() {
      run.scheduleOnce('afterRender', this, 'draw');
    },

    draw: function draw() {
      var $element = this.$('.chart-container');
      var mode = get(this, 'mode');
      var completeChartOptions = [get(this, 'buildOptions'), get(this, 'callback')];

      if (typeof mode === 'string' && !!mode) {
        completeChartOptions.unshift(mode);
      }

      if ($element) {
        var chart = $element.highcharts.apply($element, completeChartOptions).highcharts();
        set(this, 'chart', chart);
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.drawAfterRender();
      (0, _emberHighchartsUtilsOptionLoader.setDefaultHighChartOptions)(getOwner(this));
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (get(this, 'chart')) {
        get(this, 'chart').destroy();
      }
    }
  });
});
/* global Highcharts */