define('ember-date-components/utils/set-time-on-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setTimeOnDate = setTimeOnDate;
  function setTimeOnDate(date, time) {
    var hours = time ? time.hours() : 0;
    var minutes = time ? time.minutes() : 0;
    var newDate = date.clone().startOf('day').hours(hours).minutes(minutes);
    return newDate;
  }

  exports.default = setTimeOnDate;
});