define('ember-date-components/components/time-picker', ['exports', 'ember-date-components/templates/components/time-picker', 'moment', 'ember-date-components/utils/parse-time', 'ember-date-components/utils/build-time-range'], function (exports, _timePicker, _moment, _parseTime, _buildTimeRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _timePicker.default,

    classNames: ['time-picker__wrapper'],
    classNameBindings: ['isOpen:time-picker__wrapper--open'],

    /**
     * The current value of the time picker.
     * Has to be a moment.js object or null.
     *
     * @attribute value
     * @type {Date}
     * @public
     */
    value: null,

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * The action to call when the time changes.
     *
     * @event action
     * @param {Date} time The new time
     * @public
     */
    action: null,

    /**
     * If the display format should use am/pm or the 24:00 format.
     * By default, this will be determined by checking the localized date formatting of moment.js.
     * However, if you don't use moment.js-locales, this will always return true (because the default locale, 'en', uses am/pm).
     * In such a case, you can just overwrite this.
     *
     * @attribute amPm
     * @type {Boolean}
     * @public
     */
    amPm: Ember.computed(function () {
      return (0, _moment.default)().startOf('day').format('LLL').toLowerCase().indexOf('am') > -1;
    }),

    /**
     * The minimum time which can be selected.
     * This should be either a parseable string or a moment.js object.
     *
     * @attribute minTime
     * @type {String|Object}
     * @default '00:00'
     * @public
     */
    minTime: '00:00',

    /**
     * The maximum time which can be selected.
     * This should be either a parseable string or a moment.js object.
     *
     * @attribute minTime
     * @type {String|Object}
     * @default '23:59'
     * @public
     */
    maxTime: '23:59',

    /**
     * The placeholder for the time input.
     *
     * @attribute placeholder
     * @type {String}
     * @public
     */
    placeholder: 'Enter time...',

    /**
     * The step in minutes which can be selected.
     * Entered times will be rounded to this accuracy.
     * If you don't specify a selectStep separately, this value will also be used as selectStep.
     *
     * @attribute step
     * @type {Number}
     * @default 30
     * @public
     */
    step: 30,

    /**
     * The step from which dates can be selected in the dropdown.
     * If this is not explicitly set, step will be used for this value as well.
     * However, if values like 22:14 should be allowed but not shown in the dropdown,
     * this can be set to a different value.
     *
     * @attribute selectStep
     * @type {Number}
     * @default 30
     * @public
     */
    selectStep: null,

    /**
     * Classes which should be added to the input.
     *
     * @attribute inputClasses
     * @type {String}
     * @public
     */
    inputClasses: '',

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * @attribute value
     * @type {Boolean}
     * @public
     */
    renderInPlace: false,

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * Available values are right|center|left
     *
     * @attribute value
     * @type {String}
     * @public
     */
    horizontalPosition: 'auto',

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * Available values are above|below
     *
     * @attribute value
     * @type {String}
     * @public
     */
    verticalPosition: 'auto',

    /**
     * Classes which should be added to the dropdown container.
     *
     * @attribute dropdownClasses
     * @type {String}
     * @public
     */
    dropdownClasses: '',

    /**
     * If the dropdown is open.
     *
     * @property isOpen
     * @type {Boolean}
     * @protected
     */
    isOpen: false,

    /**
     * Which option is currently selected.
     * If -1, no option is selected.
     *
     * @property selected
     * @type {Number}
     * @protected
     */
    selected: -1,

    /**
     * The general options for this component.
     * These are built from the single attributes, but you could theoretically overwrite this if you need custom behaviour.
     * The options should always be fetched via this object.
     *
     * @property options
     * @type {amPm, step, minTime, maxTime}
     * @protected
     */
    options: Ember.computed(function () {
      var amPm = Ember.get(this, 'amPm');
      var minTime = Ember.get(this, 'minTime');
      var maxTime = Ember.get(this, 'maxTime');
      var step = Ember.get(this, 'step');
      var selectStep = Ember.get(this, 'selectStep');

      return {
        amPm: amPm,
        step: step,
        selectStep: selectStep,
        minTime: (0, _parseTime.default)(minTime),
        maxTime: (0, _parseTime.default)(maxTime)
      };
    }),

    /**
     * The internal value.
     * This is used to avoid two-way databinding.
     *
     * @property _value
     * @type {Object|null}
     * @private
     */
    _value: null,

    /**
     * The internal string representation of the value, e.g. the formatted value.
     *
     * @property stringValue
     * @type {String}
     * @protected
     */
    stringValue: null,

    /**
     * The value that is currently entered in the input field.
     *
     * @property inputValue
     * @type {String}
     * @protected
     */
    inputValue: null,

    /**
     * The value which is currently displayed.
     * This is either inputValue, if it is not null, or else stringValue.
     *
     * @property displayValue
     * @type {String}
     * @protected
     */
    displayValue: Ember.computed('inputValue', 'stringValue', function () {
      var inputValue = Ember.get(this, 'inputValue');
      var value = Ember.get(this, 'stringValue');
      return Ember.isNone(inputValue) ? value : inputValue;
    }),

    /**
     * The format which should be used.
     * By default, this is computed via the amPm setting.
     * You can overwrite this if necessary.
     *
     * @property format
     * @type {String}
     * @protected
     */
    format: Ember.computed('options.amPm', function () {
      var _EmberGet = Ember.get(this, 'options'),
          amPm = _EmberGet.amPm;

      return amPm ? 'hh:mm a' : 'HH:mm';
    }),

    /**
     * The options to chose from in the dropdown.
     *
     * @property timeOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    timeOptions: Ember.computed('options.{minTime,maxTime,selectStep}', function () {
      var _EmberGet2 = Ember.get(this, 'options'),
          minTime = _EmberGet2.minTime,
          maxTime = _EmberGet2.maxTime,
          selectStep = _EmberGet2.selectStep;

      var format = Ember.get(this, 'format');

      var steps = (0, _buildTimeRange.default)({
        minTime: minTime,
        maxTime: maxTime,
        step: selectStep
      });

      return steps.map(function (time) {
        return {
          value: time.format(format),
          time: time
        };
      });
    }),

    /**
     * The options for the dropdown which are currently visible.
     * This filters the timeOptions by the inputValue.
     *
     * @property filteredOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    filteredOptions: Ember.computed('timeOptions.[]', 'inputValue', function () {
      var val = (Ember.get(this, 'inputValue') || '').toLowerCase();
      var options = Ember.get(this, 'timeOptions');

      return options.filter(function (option) {
        var optionValue = Ember.get(option, 'value');
        return optionValue.toLowerCase().indexOf(val) > -1;
      });
    }),

    /**
     * The API of ember-basic-dropdown.
     * This is used to manually open/close the dropdown.
     *
     * @property _dropdownApi
     * @type {Object}
     * @private
     */
    _dropdownApi: null,

    /**
     * Open the dropdown.
     *
     * @method _open
     * @private
     */
    _open: function _open() {
      if (Ember.get(this, 'disabled')) {
        return;
      }
      Ember.set(this, 'isOpen', true);
    },


    /**
     * Close the dropdown.
     *
     * @method _close
     * @private
     */
    _close: function _close() {
      var forceCloseDropdown = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      Ember.set(this, 'isOpen', false);
      this._reset();

      if (forceCloseDropdown) {
        this._closeDropdown();
      }
    },
    _closeDropdown: function _closeDropdown() {
      var dropdownApi = Ember.get(this, '_dropdownApi');
      if (dropdownApi) {
        dropdownApi.actions.close();
      }
    },


    /**
     * Reset the temporary values.
     *
     * @method _reset
     * @private
     */
    _reset: function _reset() {
      Ember.set(this, 'selected', -1);
      Ember.set(this, 'inputValue', null);

      // Set value correctly
      this._initValue();
    },


    /**
     * Check new value..
     * If they have changed, send the action & set them on the component.
     *
     * @method _checkNewValue
     * @param {Object} newValue A new moment.js object
     * @private
     */
    _checkNewValue: function _checkNewValue(newValue) {
      if (newValue !== Ember.get(this, '_value')) {
        Ember.set(this, '_value', newValue);
        this._sendAction();
      }
    },


    /**
     * Check stringValue and generate the new value from it.
     *
     * @method _checkInput
     * @private
     */
    _checkInput: function _checkInput() {
      var value = (Ember.get(this, 'stringValue') || '').toLowerCase();
      var newValue = (0, _parseTime.default)(value);
      this._checkNewValue(newValue);
    },


    /**
     * Check the inputValue as string and generate the new value from it.
     *
     * @method _checkStringInput
     * @private
     */
    _checkStringInput: function _checkStringInput() {
      var inputValue = Ember.get(this, 'inputValue');
      var newValue = (0, _parseTime.default)(inputValue);

      if (!newValue) {
        Ember.set(this, 'stringValue', null);
        this._checkNewValue();
        return;
      }

      var format = Ember.get(this, 'format');
      var time = this._normalizeTime(newValue);

      var value = time.format(format);

      Ember.set(this, 'stringValue', value);
      this._checkInput();
    },


    /**
     * Takes a moment.js object and normalizes it to the nearest step.
     *
     * @method _normalizeTime
     * @param time
     * @param step
     * @returns {*}
     * @private
     */
    _normalizeTime: function _normalizeTime(time) {
      var _EmberGet3 = Ember.get(this, 'options'),
          minTime = _EmberGet3.minTime,
          maxTime = _EmberGet3.maxTime,
          step = _EmberGet3.step;

      var min = minTime ? minTime.valueOf() : null;
      var max = maxTime ? maxTime.valueOf() : null;
      step = !Ember.isNone(step) ? step : 30;
      var val = time ? time.valueOf() : null;

      // if time is before minTime, return minTime
      if (!Ember.isNone(min) && val < min) {
        return (0, _moment.default)(min);
      }

      // if time is after maxTime, return maxTime
      if (!Ember.isNone(max) && val > max) {
        return (0, _moment.default)(max);
      }

      // if time is not in step range, round it up/down
      var stepMs = step * 60 * 1000;
      var diff = val % stepMs;
      if (diff !== 0) {
        // If diff > 50%, round up, elese round down
        if (diff * 2 > stepMs) {
          return (0, _moment.default)(val + stepMs - diff);
        } else {
          return (0, _moment.default)(val - diff);
        }
      }

      return time;
    },


    /**
     * Send the action.
     * The action receives a moment.js object or null as parameter.
     *
     * @method _sendAction
     * @private
     */
    _sendAction: function _sendAction() {
      var value = Ember.get(this, '_value') || null;
      var action = Ember.get(this, 'action');

      if (action && !Ember.get(this, 'disabled')) {
        action(value);
      }

      this._close();
    },


    /**
     * Initialise stringValue from value.
     * This is called on reset and when the value changes from outside.
     *
     * @method _initValue
     * @private
     */
    _initValue: function _initValue() {
      var value = Ember.get(this, '_value');
      var format = Ember.get(this, 'format');

      value = (0, _parseTime.default)(value);
      value = _moment.default.isMoment(value) ? value.format(format) : value;
      Ember.set(this, 'stringValue', value || null);
    },
    _closeNext: function _closeNext() {
      if (Ember.get(this, 'isDestroyed') || !Ember.get(this, 'isOpen')) {
        return;
      }
      var inputValue = Ember.get(this, 'inputValue');
      // If there is an input, this means it hasn't been processed yet
      // --> Process it now!
      if (inputValue) {
        this._checkStringInput();
      }

      this._close();
    },


    /**
     * Prepare data for the time input.
     *
     * @method didReceiveAttrs
     * @protected
     * @override
     */
    didReceiveAttrs: function didReceiveAttrs() {
      // Set selectStep to step
      var step = Ember.get(this, 'step');
      if (!Ember.get(this, 'selectStep')) {
        Ember.set(this, 'selectStep', step);
      }

      // Set the internal value
      Ember.set(this, '_value', Ember.get(this, 'value'));
      this._initValue();
    },


    actions: {
      open: function open() {
        var timer = Ember.get(this, '_closeNextTimer');
        if (timer) {
          Ember.run.cancel(timer);
        }

        this._open();
      },
      openAndClear: function openAndClear() {
        Ember.set(this, 'isOpen', true);
        Ember.set(this, 'stringValue', null);
      },
      close: function close() {
        this._close();
      },
      closeNext: function closeNext() {
        // Wait for all other events to finish
        var closeNext = Ember.run.debounce(this, this._closeNext, 100);
        Ember.set(this, '_closeNextTimer', closeNext);
      },
      selectUp: function selectUp() {
        this.decrementProperty('selected');
        if (Ember.get(this, 'selected') < -1) {
          Ember.set(this, 'selected', -1);
        }
      },
      selectDown: function selectDown() {
        this.incrementProperty('selected');
        var optionsLength = Ember.get(this, 'filteredOptions.length');

        if (Ember.get(this, 'selected') > optionsLength) {
          Ember.set(this, 'selected', optionsLength - 1);
        }
      },
      selectCurrent: function selectCurrent() {
        var options = Ember.get(this, 'filteredOptions');
        var selected = Ember.get(this, 'selected');

        // If nothing is selected, simply try to parse the entered string
        if (selected === -1) {
          this._checkStringInput();
          return;
        }

        var selectedOption = options[selected];

        // If, for whatever reason, the selected options doesn't exist
        // Just parse the string - this should't happen, normally
        if (!selectedOption) {
          this._checkStringInput();
          return;
        }

        // Actually set stringValue and check the input
        var value = Ember.get(selectedOption, 'value');
        Ember.set(this, 'stringValue', value);
        this._checkInput();
        this._close();
      },
      selectValue: function selectValue(value) {
        Ember.set(this, 'stringValue', value);
        this._checkInput();
        this._close();
      },
      updateInputValue: function updateInputValue(val) {
        // Always open the select box when someone starts to type
        this._open();
        Ember.set(this, 'inputValue', val);
      },
      openDropdown: function openDropdown(dropdownApi) {
        Ember.set(this, '_dropdownApi', dropdownApi);
      },
      closeDropdown: function closeDropdown() {
        this._close(false);
      },
      onKeyDown: function onKeyDown(dropdownApi, event) {
        // Also handle the enter event here, since ember-basic-dropdown seems to be interfering somewhere
        var keyCode = event.keyCode;

        var enterKeyCode = 13;
        var tabKeyCode = 9;
        var spaceKeyCode = 32;
        if (keyCode === enterKeyCode || keyCode === tabKeyCode) {
          this.send('selectCurrent');
          return false;
        }
        if (keyCode === spaceKeyCode) {
          return false;
        }
      }
    }
  });
});