define('ember-changeset/index', ['exports', 'ember-changeset/-private/relay', 'ember-changeset/utils/computed/object-to-array', 'ember-changeset/utils/computed/is-empty-object', 'ember-changeset/utils/computed/inflate', 'ember-changeset/utils/computed/transform', 'ember-changeset/utils/is-promise', 'ember-changeset/utils/is-object', 'ember-changeset/utils/assign', 'ember-changeset/utils/includes', 'ember-changeset/utils/take', 'ember-changeset/utils/is-changeset', 'ember-changeset/utils/is-relay', 'ember-changeset/utils/set-nested-property', 'ember-changeset/utils/merge-nested', 'ember-changeset/utils/validate-nested-obj', 'ember-changeset/utils/object-without', 'ember-changeset/-private/err', 'ember-changeset/-private/change', 'ember-deep-set'], function (exports, _relay, _objectToArray, _isEmptyObject, _inflate, _transform, _isPromise, _isObject, _assign, _includes, _take, _isChangeset, _isRelay, _setNestedProperty, _mergeNested, _validateNestedObj, _objectWithout, _err, _change, _emberDeepSet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeset = changeset;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var keys = Object.keys;

  var CONTENT = '_content';
  var CHANGES = '_changes';
  var ERRORS = '_errors';
  var RELAY_CACHE = '_relayCache';
  var VALIDATOR = '_validator';
  var OPTIONS = '_options';
  var RUNNING_VALIDATIONS = '_runningValidations';
  var BEFORE_VALIDATION_EVENT = 'beforeValidation';
  var AFTER_VALIDATION_EVENT = 'afterValidation';
  var defaultValidatorFn = function defaultValidatorFn() {
    return true;
  };
  var defaultOptions = { skipValidate: false };

  /*::
  type Changes            = { [string]: Change   };
  type Errors             = { [string]: Err      };
  type RelayCache         = { [string]: RelayDef };
  type RunningValidations = { [string]: number   };
  
  type InternalMap =
    | Changes
    | Errors
    | RelayCache
    | RunningValidations;
  
  type NewProperty<T> = {
    key:       string,
    value:     T,
    oldValue?: mixed,
  };
  
  type InternalMapKey =
    | '_changes'
    | '_errors'
    | '_relayCache'
    | '_runningValidations';
  
  type Snapshot = {
    changes: { [string]: mixed          },
    errors:  { [string]: ErrLike<mixed> },
  };
  
  type Inflated<T> = {
    [string]: Inflated<T> | T,
  };
  
  export type ChangesetDef = {|
    _content:            Object,
    _changes:            Changes,
    _errors:             Errors,
    _relayCache:         RelayCache,
    _validator:          ValidatorFunc,
    _options:            Config,
    _runningValidations: RunningValidations,
    __changeset__:       '__CHANGESET__',
    _bareChanges:        { [string]: mixed },
  
    changes: Array<{ key: string }>,
    errors:  Array<{ key: string }>,
    change:  Inflated<mixed>,
    error:   Inflated<ErrLike<mixed>>,
  
    isValid:    boolean,
    isPristine: boolean,
    isInvalid:  boolean,
    isDirty:    boolean,
  
    _super: () => void,
    init: () => void,
    unknownProperty: (string) => mixed,
    get: (string) => mixed,
    _valueFor: (string, ?boolean) => RelayDef | mixed,
    _relayFor: (string, Object) => RelayDef,
    toString: () => string,
    _deleteKey: (InternalMapKey, string) => void,
    notifyPropertyChange: (string) => void,
    addError: <T: string | ErrLike<*>>(string, T) => T,
    _setProperty: <T>(ValidationResult, NewProperty<T>) => (T | ErrLike<T>),
    _validateAndSet: <T>(string, T) => (Promise<T> | Promise<ErrLike<T>> | T | ErrLike<T>),
    _setIsValidating: (string, boolean) => void,
    _validate: (string, mixed, mixed) => (ValidationResult | Promise<ValidationResult>),
    trigger: (string, string) => void,
    isValidating: (string | void) => boolean,
    cast: (Array<string>) => ChangesetDef,
    willDestroy: () => void,
    setUnknownProperty: <T>(string, T) => (T | ErrLike<T> | Promise<T> | Promise<ErrLike<T>>),
    prepare: (({ [string]: mixed }) => ({ [string]: mixed })) => ChangesetDef,
    execute: () => ChangesetDef,
    _notifyVirtualProperties: (?Array<string>) => void,
    _rollbackKeys: () => Array<string>,
    rollback: () => ChangesetDef,
    save: (Object) => Promise<ChangesetDef | mixed>,
    merge: (ChangesetDef) => ChangesetDef,
    validate: (string | void) => (Promise<null> | Promise<mixed | ErrLike<mixed>> | Promise<Array<mixed | ErrLike<mixed>>>),
    pushErrors: (string, ...string) => ErrLike<mixed>,
    snapshot: () => Snapshot,
    restore: (Snapshot) => ChangesetDef,
  |};
  */

  /**
   * Creates new changesets.
   *
   * @uses Ember.Evented
   */
  function changeset(obj /*: Object                      */
  ) /*: Class<ChangesetDef> */{
    var validateFn /*: ValidatorFunc               */ = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
    var validationMap /*: { [string]: ValidatorFunc } */ = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options /*: Config                      */ = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    (true && !(Ember.isPresent(obj)) && Ember.assert('Underlying object for changeset is missing', Ember.isPresent(obj)));


    return Ember.Object.extend(Ember.Evented, {
      /**
       * Internal descriptor for changeset identification.
       */
      __changeset__: _isChangeset.CHANGESET,

      changes: (0, _objectToArray.default)(CHANGES, function (c /*: Change */) {
        return c.value;
      }, false),
      errors: (0, _objectToArray.default)(ERRORS, function (e /*: Err */) {
        return { value: e.value, validation: e.validation };
      }, true),
      change: (0, _inflate.default)(CHANGES, function (c) {
        return c.value;
      }),
      error: (0, _inflate.default)(ERRORS, function (e) {
        return { value: e.value, validation: e.validation };
      }),

      isValid: (0, _isEmptyObject.default)(ERRORS),
      isPristine: (0, _isEmptyObject.default)(CHANGES),
      isInvalid: Ember.computed.not('isValid').readOnly(),
      isDirty: Ember.computed.not('isPristine').readOnly(),

      _bareChanges: (0, _transform.default)(CHANGES, function (c) {
        return c.value;
      }),

      init: function init() {
        var c /*: ChangesetDef */ = this;
        c._super.apply(c, arguments);
        c[CONTENT] = obj;
        c[CHANGES] = {};
        c[ERRORS] = {};
        c[RELAY_CACHE] = {};
        c[VALIDATOR] = validateFn;
        c[OPTIONS] = (0, _assign.default)(defaultOptions, options);
        c[RUNNING_VALIDATIONS] = {};
      },
      unknownProperty: function unknownProperty(key /*: string */
      ) /*: RelayDef | mixed */{
        return this /*: ChangesetDef */._valueFor(key);
      },
      setUnknownProperty /*:: <T> */: function setUnknownProperty(

      /**
       * Stores change on the changeset.
       */
      key /*: string */
      , value /*: T      */
      ) /*: T | ErrLike<T> | Promise<T> | Promise<ErrLike<T>> */{
        var config /*: Config       */ = Ember.get(this, OPTIONS);
        var skipValidate /*: boolean      */ = Ember.get(config, 'skipValidate');
        var c /*: ChangesetDef */ = this;

        if (skipValidate) {
          return c._setProperty(true, { key: key, value: value });
        }

        return c._validateAndSet(key, value);
      },
      toString: function toString() /*: string */{
        var normalisedContent /*: Object */ = (0, _assign.default)(Ember.get(this, CONTENT), {});
        return 'changeset:' + normalisedContent.toString();
      },
      willDestroy: function willDestroy() /*: void */{
        var relayCache /*: RelayCache */ = Ember.get(this, RELAY_CACHE);
        for (var key in relayCache) {
          relayCache[key].destroy();
        }
      },
      prepare: function prepare(prepareChangesFn /*: ({ [string]: mixed }) => ({ [string]: mixed }) */
      ) /*: ChangesetDef */{
        var changes /*: { [string]: mixed } */ = Ember.get(this, '_bareChanges');
        var preparedChanges = prepareChangesFn(changes);

        (true && !((0, _isObject.default)(preparedChanges)) && Ember.assert('Callback to `changeset.prepare` must return an object', (0, _isObject.default)(preparedChanges)));

        (0, _validateNestedObj.default)('preparedChanges', preparedChanges);

        var newChanges /*: Changes */ = keys(preparedChanges).reduce(function (newObj, key) {
          newObj[key] = new _change.default(preparedChanges[key]);
          return newObj;
        }, {});

        Ember.set(this, CHANGES, newChanges);
        return this;
      },
      execute: function execute() /*: ChangesetDef */{
        if (Ember.get(this, 'isValid') && Ember.get(this, 'isDirty')) {
          var content /*: Object  */ = Ember.get(this, CONTENT);
          var changes /*: Changes */ = Ember.get(this, CHANGES);
          keys(changes).forEach(function (key) {
            return (0, _emberDeepSet.default)(content, key, changes[key].value);
          });
        }

        return this;
      },
      save: function save(options /*: Object */
      ) /*: Promise<ChangesetDef | mixed> */{
        var _this = this;

        var content /*: Object */ = Ember.get(this, CONTENT);
        var savePromise /*: mixed | Promise<ChangesetDef | mixed> */ = Ember.RSVP.resolve(this);
        this /*: ChangesetDef */.execute();

        if (Ember.typeOf(content.save) === 'function') {
          var result /*: mixed | Promise<mixed> */ = content.save(options);
          savePromise = result;
        }

        return Ember.RSVP.resolve(savePromise).then(function (result) {
          _this. /*: ChangesetDef */rollback();
          return result;
        });
      },
      merge: function merge(changeset /*: ChangesetDef */
      ) /*: ChangesetDef */{
        var content /*: Object */ = Ember.get(this, CONTENT);
        (true && !((0, _isChangeset.default)(changeset)) && Ember.assert('Cannot merge with a non-changeset', (0, _isChangeset.default)(changeset)));
        (true && !(Ember.get(changeset, CONTENT) === content) && Ember.assert('Cannot merge with a changeset of different content', Ember.get(changeset, CONTENT) === content));


        if (Ember.get(this, 'isPristine') && Ember.get(changeset, 'isPristine')) {
          return this;
        }

        // Note: we do not need to merge the RelayCache because the new
        // changeset will create its own relays if necessary.

        var c1 /*: Changes    */ = Ember.get(this, CHANGES);
        var c2 /*: Changes    */ = Ember.get(changeset, CHANGES);
        var e1 /*: Errors     */ = Ember.get(this, ERRORS);
        var e2 /*: Errors     */ = Ember.get(changeset, ERRORS);

        var newChangeset /*: ChangesetDef */ = new Changeset(content, Ember.get(this, VALIDATOR));
        var newErrors /*: Errors  */ = (0, _objectWithout.default)(keys(c2), e1);
        var newChanges /*: Changes */ = (0, _objectWithout.default)(keys(e2), c1);
        var mergedErrors /*: Errors  */ = (0, _mergeNested.default)(newErrors, e2);
        var mergedChanges /*: Changes */ = (0, _mergeNested.default)(newChanges, c2);

        newChangeset[ERRORS] = mergedErrors;
        newChangeset[CHANGES] = mergedChanges;
        newChangeset._notifyVirtualProperties();
        return newChangeset;
      },
      rollback: function rollback() /*: ChangesetDef */{
        // Notify keys contained in relays.
        var relayCache /*: RelayCache */ = Ember.get(this, RELAY_CACHE);
        for (var key in relayCache) {
          relayCache[key].rollback();
        } // Get keys before reset.
        var keys = this /*: ChangesetDef */._rollbackKeys();

        // Reset.
        Ember.set(this, RELAY_CACHE, {});
        Ember.set(this, CHANGES, {});
        Ember.set(this, ERRORS, {});
        this /*: ChangesetDef */._notifyVirtualProperties(keys);

        return this;
      },
      validate: function validate(key /*: string | void */
      ) /*: Promise<null> | Promise<mixed | ErrLike<mixed>> | Promise<Array<mixed | ErrLike<mixed>>> */{
        if (keys(validationMap).length === 0) {
          return Ember.RSVP.resolve(null);
        }

        var c /*: ChangesetDef */ = this;

        if (Ember.isNone(key)) {
          var maybePromise = keys(validationMap).map(function (validationKey) {
            var isPlain = true;
            return c._validateAndSet(validationKey, c._valueFor(validationKey, isPlain));
          });

          return Ember.RSVP.all(maybePromise);
        }

        var k /*: string */ = key /*: any */;
        var isPlain = true;
        return Ember.RSVP.resolve(c._validateAndSet(k, c._valueFor(k, isPlain)));
      },
      addError /*:: <T: string | ErrLike<*>> */: function addError(

      /**
       * Manually add an error to the changeset. If there is an existing
       * error or change for `key`, it will be overwritten.
       */
      key /*: string */
      , error /*: T      */
      ) /*: T */{
        // Construct new `Err` instance.
        var newError = void 0 /*: Err */;
        if ((0, _isObject.default)(error)) {
          var errorLike /*: ErrLike<*> */ = error /*: any */;
          (true && !(errorLike.hasOwnProperty('value')) && Ember.assert('Error must have value.', errorLike.hasOwnProperty('value')));
          (true && !(errorLike.hasOwnProperty('validation')) && Ember.assert('Error must have validation.', errorLike.hasOwnProperty('validation')));

          newError = new _err.default(errorLike.value, errorLike.validation);
        } else {
          var validation /*: ValidationErr */ = error /*: any */;
          newError = new _err.default(Ember.get(this, key), validation);
        }

        // Remove `key` from changes map.
        var c = this /*: ChangesetDef */;
        c._deleteKey(CHANGES, key);

        // Add `key` to errors map.
        var errors /*: Errors */ = Ember.get(this, ERRORS);
        (0, _setNestedProperty.default)(errors, key, newError);
        c.notifyPropertyChange(ERRORS);

        // Notify that `key` has changed.
        c.notifyPropertyChange(key);

        // Return passed-in `error`.
        return error;
      },
      pushErrors: function pushErrors(key /*: string        */
      ) /*: Array<string> */
      /*: ErrLike<mixed> */{
        var errors /*: Errors */ = Ember.get(this, ERRORS);
        var existingError /*: Err */ = errors[key] || new _err.default(null, []);
        var validation /*: ValidationErr */ = existingError.validation;
        var value /*: mixed */ = Ember.get(this, key);

        if (!Ember.isArray(validation) && Ember.isPresent(validation)) {
          var _v /*: string */ = existingError.validation /*: any */;
          existingError.validation = [_v];
        }

        var v /*: Array<string> */ = existingError.validation /*: any */;

        for (var _len = arguments.length, newErrors = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          newErrors[_key - 1] = arguments[_key];
        }

        validation = [].concat(_toConsumableArray(v), _toConsumableArray(newErrors));

        var c = this /*: ChangesetDef */;
        c._deleteKey(CHANGES, key);
        c.notifyPropertyChange(ERRORS);
        c.notifyPropertyChange(key);

        errors[key] = new _err.default(value, validation);
        return { value: value, validation: validation };
      },
      snapshot: function snapshot() /*: Snapshot */{
        var changes /*: Changes */ = Ember.get(this, CHANGES);
        var errors /*: Errors  */ = Ember.get(this, ERRORS);

        return {
          changes: keys(changes).reduce(function (newObj, key) {
            newObj[key] = changes[key].value;
            return newObj;
          }, {}),

          errors: keys(errors).reduce(function (newObj, key) {
            var e = errors[key];
            newObj[key] = { value: e.value, validation: e.validation };
            return newObj;
          }, {})
        };
      },
      restore: function restore(_ref) /*: ChangesetDef */{
        var changes = _ref.changes,
            errors = _ref.errors;

        (0, _validateNestedObj.default)('snapshot.changes', changes);
        (0, _validateNestedObj.default)('snapshot.errors', errors);

        var newChanges /*: Changes */ = keys(changes).reduce(function (newObj, key) {
          newObj[key] = new _change.default(changes[key]);
          return newObj;
        }, {});

        var newErrors /*: Errors */ = keys(errors).reduce(function (newObj, key) {
          var e /*: ErrLike<*> */ = errors[key];
          newObj[key] = new _err.default(e.value, e.validation);
          return newObj;
        }, {});

        Ember.set(this, CHANGES, newChanges);
        Ember.set(this, ERRORS, newErrors);

        this /*: ChangesetDef */._notifyVirtualProperties();
        return this;
      },
      cast: function cast() /*: ChangesetDef */{
        var allowed /*: Array<string> */ = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var changes /*: Changes */ = Ember.get(this, CHANGES);

        if (Ember.isArray(allowed) && allowed.length === 0) {
          return this;
        }

        var changeKeys /*: Array<string> */ = keys(changes);
        var validKeys = Ember.A(changeKeys).filter(function (key /*: string */) {
          return (0, _includes.default)(allowed, key);
        });
        var casted = (0, _take.default)(changes, validKeys);
        Ember.set(this, CHANGES, casted);
        return this;
      },
      isValidating: function isValidating(key /*: string | void */) /*: boolean */{
        var runningValidations /*: RunningValidations */ = Ember.get(this, RUNNING_VALIDATIONS);
        var ks /*: Array<string> */ = Ember.A(keys(runningValidations));
        if (key) return (0, _includes.default)(ks, key);
        return !Ember.isEmpty(ks);
      },
      _validateAndSet /*:: <T> */: function _validateAndSet(

      /**
       * For a given key and value, set error or change.
       */
      key /*: string */
      , value /*: T      */
      ) /*: Promise<T> | Promise<ErrLike<T>> | T | ErrLike<T> */{
        var c /*: ChangesetDef     */ = this;
        var content /*: Object           */ = Ember.get(this, CONTENT);
        var oldValue /*: mixed            */ = Ember.get(content, key);
        var validation /*: ValidationResult | Promise<ValidationResult> */ = c._validate(key, value, oldValue);

        // TODO: Address case when Promise is rejected.
        if ((0, _isPromise.default)(validation)) {
          c._setIsValidating(key, true);
          c.trigger(BEFORE_VALIDATION_EVENT, key);

          var _v2 /*: Promise<ValidationResult> */ = validation /*: any */;
          return _v2.then(function (resolvedValidation) {
            c._setIsValidating(key, false);
            c.trigger(AFTER_VALIDATION_EVENT, key);
            return c._setProperty(resolvedValidation, { key: key, value: value, oldValue: oldValue });
          });
        }

        c.trigger(BEFORE_VALIDATION_EVENT, key);
        c.trigger(AFTER_VALIDATION_EVENT, key);
        var v /*: ValidationResult */ = validation /*: any */;
        return c._setProperty(v, { key: key, value: value, oldValue: oldValue });
      },
      _validate: function _validate(key /*: string */
      , newValue /*: mixed  */
      , oldValue /*: mixed  */
      ) /*: ValidationResult | Promise<ValidationResult> */{
        var validator /*: ValidatorFunc */ = Ember.get(this, VALIDATOR);
        var content /*: Object        */ = Ember.get(this, CONTENT);

        if (Ember.typeOf(validator) === 'function') {
          var isValid = validator({
            key: key,
            newValue: newValue,
            oldValue: oldValue,
            changes: Ember.get(this, 'change'),
            content: content
          });

          return Ember.isPresent(isValid) ? isValid : true;
        }

        return true;
      },
      _setProperty /*:: <T> */: function _setProperty(

      /**
       * Sets property or error on the changeset.
       */
      validation /*: ValidationResult */
      , _ref2) /*: T | ErrLike<T> */{
        var key = _ref2.key,
            value = _ref2.value,
            oldValue = _ref2.oldValue;
        (true && !(!(0, _isRelay.default)(value)) && Ember.assert('Value must not be a Relay. If you see this error, please open an issue on https://github.com/poteto/ember-changeset/issues.', !(0, _isRelay.default)(value)));


        var changes /*: Changes */ = Ember.get(this, CHANGES);
        var isValid /*: boolean */ = validation === true || Ember.isArray(validation) && validation.length === 1 && validation /*: any */[0] === true;

        // Shorthand for `this`.
        var c /*: ChangesetDef */ = this;

        // Error case.
        if (!isValid) {
          var v /*: ValidationErr */ = validation /*: any */;
          return c.addError(key, { value: value, validation: v });
        }

        // Happy path: remove `key` from error map.
        c._deleteKey(ERRORS, key);

        // Happy path: update change map.
        if (!Ember.isEqual(oldValue, value)) {
          (0, _setNestedProperty.default)(changes, key, new _change.default(value));
        } else if (key in changes) {
          c._deleteKey(CHANGES, key);
        }

        // Happy path: notify that `key` was added.
        c.notifyPropertyChange(CHANGES);
        c.notifyPropertyChange(key);

        // Return new value.
        return value;
      },
      _setIsValidating: function _setIsValidating(key /*: string  */
      , value /*: boolean */
      ) /*: void */{
        var running /*: RunningValidations */ = Ember.get(this, RUNNING_VALIDATIONS);
        var count /*: number             */ = Ember.get(running, key) || 0;

        if (!value && count === 1) {
          delete running[key];
          return;
        }

        Ember.set(running, key, value ? count + 1 : count - 1);
      },
      _valueFor: function _valueFor(key /*: string  */
      ) /*: RelayDef | mixed */{
        var plainValue /*: ?boolean */ = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var changes /*: Changes */ = Ember.get(this, CHANGES);
        var errors /*: Errors  */ = Ember.get(this, ERRORS);
        var content /*: Object  */ = Ember.get(this, CONTENT);

        if (errors.hasOwnProperty(key)) {
          var e /*: Err */ = errors[key];
          return e.value;
        }

        var original /*: mixed */ = Ember.get(content, key);
        if ((0, _isObject.default)(original) && !plainValue) {
          var c /*: ChangesetDef */ = this;
          var o /*: Object       */ = original /*: any */;
          return c._relayFor(key, o);
        }

        if (changes.hasOwnProperty(key)) {
          var _c /*: Change */ = changes[key];
          return _c.value;
        }

        return original;
      },
      _relayFor: function _relayFor(key /*: string */
      , value /*: Object */
      ) /*: RelayDef */{
        var cache /*: RelayCache */ = Ember.get(this, RELAY_CACHE);

        if (!(key in cache)) {
          cache[key] = _relay.default.create({ key: key, changeset: this, content: value });
        }

        return cache[key];
      },
      _notifyVirtualProperties: function _notifyVirtualProperties() /*: void */{
        var _this2 = this;

        var keys /*: ?Array<string> */ = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this /*: ChangesetDef */._rollbackKeys();

        (keys || []).forEach(function (key) {
          return _this2. /*: ChangesetDef */notifyPropertyChange(key);
        });
      },
      _rollbackKeys: function _rollbackKeys() /*: Array<string> */{
        var changes /*: Changes */ = Ember.get(this, CHANGES);
        var errors /*: Errors  */ = Ember.get(this, ERRORS);
        return Ember.A([].concat(_toConsumableArray(keys(changes)), _toConsumableArray(keys(errors)))).uniq();
      },
      _deleteKey: function _deleteKey(objName /*: string */
      ) /*: void */{
        var key /*: string */ = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        var obj /*: InternalMap */ = Ember.get(this, objName);
        if (obj.hasOwnProperty(key)) delete obj[key];
        var c /*: ChangesetDef */ = this;
        c.notifyPropertyChange(objName + '.' + key);
        c.notifyPropertyChange(objName);
      },
      get: function get(keyName /*: string */) /*: mixed */{
        var result = this._super(keyName);
        if ((0, _isRelay.default)(result)) return Ember.get(result, 'content');
        return result;
      }
    } /*: ChangesetDef */);
  }

  var Changeset =
  /**
   * Changeset factory
   *
   * @class Changeset
   * @constructor
   */
  function Changeset() /*: Array<any> */{
    _classCallCheck(this, Changeset);

    return changeset.apply(undefined, arguments).create();
  };

  exports.default = Changeset;
});