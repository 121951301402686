define('ember-data-model-fragments/transforms/array', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.array` that transforms array data with the given transform
    type.
  
    @class ArrayTransform
    @namespace MF
    @extends DS.Transform
  */
  var ArrayTransform = _transform.default.extend({
    store: null,
    type: null,

    deserialize: function deserializeArray(data) {
      if (data == null) {
        return null;
      }

      var transform = Ember.get(this, 'transform');

      data = Ember.makeArray(data);

      if (!transform) {
        return data;
      }

      return data.map(transform.deserialize, transform);
    },

    serialize: function serializeArray(array) {
      if (array == null) {
        return null;
      }

      var transform = Ember.get(this, 'transform');

      array = array.toArray ? array.toArray() : array;

      if (!transform) {
        return array;
      }

      return array.map(transform.serialize, transform);
    },

    transform: Ember.computed('type', function () {
      var attributeType = this.get('type');

      if (!attributeType) {
        return null;
      }

      var transform = Ember.getOwner(this).lookup('transform:' + attributeType);
      (true && !(!!transform) && Ember.assert('Unable to find transform for \'' + attributeType + '\'', !!transform));


      return transform;
    })
  });

  exports.default = ArrayTransform;
});