define('ember-date-components/components/date-picker-month-year-select', ['exports', 'ember-date-components/templates/components/date-picker-month-year-select', 'moment'], function (exports, _datePickerMonthYearSelect, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _datePickerMonthYearSelect.default,

    minDate: null,
    maxDate: null,
    currentMonth: null,
    disableYearPicker: false,
    disableMonthPicker: false,
    availableYearOffset: 10,

    availableMonths: Ember.computed('currentMonth', 'minDate', 'maxDate', function () {
      var currentMonth = Ember.get(this, 'currentMonth');
      var year = currentMonth.year();

      var minDate = Ember.get(this, 'minDate');
      var maxDate = Ember.get(this, 'maxDate');

      minDate = minDate ? minDate.clone().startOf('month') : null;
      maxDate = maxDate ? maxDate.clone().startOf('month') : null;

      var months = [];
      for (var i = 0; i < 12; i++) {
        var date = (0, _moment.default)().year(year).month(i).startOf('month');
        if (minDate && date.diff(minDate) < 0) {
          continue;
        }
        if (maxDate && date.diff(maxDate) > 0) {
          continue;
        }
        months.push(date);
      }

      return months;
    }),

    availableYears: Ember.computed('currentMonth', 'minDate', 'maxDate', function () {
      var currentMonth = Ember.get(this, 'currentMonth');

      var minDate = Ember.get(this, 'minDate');
      var maxDate = Ember.get(this, 'maxDate');

      minDate = minDate ? minDate.clone().startOf('year') : null;
      maxDate = maxDate ? maxDate.clone().startOf('year') : null;

      var availableYearOffset = Ember.get(this, 'availableYearOffset');
      var dates = [];
      for (var i = availableYearOffset; i > 0; i--) {
        var date = currentMonth.clone().subtract(i, 'years').startOf('month');
        if (minDate && date.diff(minDate) < 0) {
          continue;
        }
        if (maxDate && date.diff(maxDate) > 0) {
          continue;
        }
        dates.push(date);
      }
      dates.push(currentMonth.clone());
      for (var _i = 1; _i <= availableYearOffset; _i++) {
        var _date = currentMonth.clone().add(_i, 'years').startOf('month');
        if (minDate && _date.diff(minDate, 'years') < 0) {
          continue;
        }
        if (maxDate && _date.diff(maxDate, 'years') > 0) {
          continue;
        }
        dates.push(_date);
      }

      return dates;
    }),

    monthPickerDisabled: Ember.computed('disableMonthPicker', 'availableMonths.length', function () {
      return Ember.get(this, 'disableMonthPicker') || !Ember.get(this, 'availableMonths.length');
    }),

    yearPickerDisabled: Ember.computed('disableYearPicker', 'availableYears.length', function () {
      return Ember.get(this, 'disableYearPicker') || !Ember.get(this, 'availableYears.length');
    }),

    actions: {
      gotoMonth: function gotoMonth(month, dropdownApi) {
        var action = Ember.get(this, 'gotoMonth');
        action(month);

        if (dropdownApi) {
          dropdownApi.actions.close();
        }
      }
    }
  });
});