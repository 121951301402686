define('ember-palette/utils/css-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cssColor;
  // From SO http://stackoverflow.com/a/33184805/483616
  function cssColor(color) {
    var ele = document.createElement('div');
    ele.style.color = color;
    return ele.style.color.split(/\s+/).join('').toLowerCase();
  }
});