define('ember-date-components/helpers/date-picker-day-classes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.datePickerDayClasses = datePickerDayClasses;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function datePickerDayClasses(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        baseClass = _ref3[0];

    var _ref2$isToday = _ref2.isToday,
        isToday = _ref2$isToday === undefined ? false : _ref2$isToday,
        _ref2$isSelected = _ref2.isSelected,
        isSelected = _ref2$isSelected === undefined ? false : _ref2$isSelected,
        _ref2$isDisabled = _ref2.isDisabled,
        isDisabled = _ref2$isDisabled === undefined ? false : _ref2$isDisabled,
        _ref2$isInRange = _ref2.isInRange,
        isInRange = _ref2$isInRange === undefined ? false : _ref2$isInRange;

    var isTodayClass = isToday ? ' ' + baseClass + '--today' : '';
    var isSelectedClass = isSelected ? ' ' + baseClass + '--selected' : '';
    var isDisabledClass = isDisabled ? ' ' + baseClass + '--disabled' : '';
    var isInRangeClass = isInRange ? ' ' + baseClass + '--in-range' : '';

    return '' + baseClass + isTodayClass + isSelectedClass + isDisabledClass + isInRangeClass;
  }

  exports.default = Ember.Helper.helper(datePickerDayClasses);
});