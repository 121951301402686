define('ember-palette/components/ember-palette-selected/component', ['exports', 'ember-palette/components/ember-palette-selected/template', 'ember-palette/utils/css-color'], function (exports, _template, _cssColor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['selected-color-item'],
    attributeBindings: ['style'],
    style: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var option = this.get('option');
      var color = (0, _cssColor.default)(option) ? option : '#374046';

      this.set('style', Ember.String.htmlSafe('background-color: ' + color));
    }
  });
});